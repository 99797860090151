import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: "",
    name: "default-container",
    component: () => import("@/layouts/default.vue"),
    redirect: { name: "contact-us", },
    children: [
      {
        path: '',
        name: '',
        component: () => import("@/views/default/home.vue"),
      },
      {
        path: 'about',
        name: 'about',
        component: () => import("@/views/default/about.vue"),
      },
      {
        path: 'contact-us',
        name: 'contact-us',
        component: () => import("@/views/default/contact-us.vue"),
      },
      {
        path: 'services',
        name: 'services',
        component: () => import("@/views/default/services.vue"),
      },
    ]
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
  }
});

export default router
